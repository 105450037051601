import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {CheckInOutComponent} from "./pages/check-in-out/check-in-out.component";
import {ListComponent} from "./pages/list/list.component";

const routes: Routes = [
  {
    path: '',
    component: ListComponent
  },
  {
    path: 'check',
    component: CheckInOutComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
