import { Component, OnInit } from '@angular/core';
import {SplashScreen} from "@capacitor/splash-screen";
import {AssetGlimpse} from "../../interfaces/asset-glimpse";
import {RestService} from "../../services/rest.service";
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import {ModalController} from "@ionic/angular";
import {AssetDetailComponent} from "../../models/asset-detail/asset-detail.component";
import {AssetNewComponent} from "../../models/asset-new/asset-new.component";
import {QrscanComponent} from "../../models/qrscan/qrscan.component";
import {StorageService} from "../../services/storage.service";
import {ASSETSTATUSESDICT} from "../../dictionaries/asset-status";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {

  inventory: AssetGlimpse[];
  rows = [];
  loadingIndicator = true;
  reorderable = true;
  selected = [];

  assetStatuses = ASSETSTATUSESDICT;

  columns = [
    { name: 'Asset', prop: 'constructAssetTag'  },
    { name: 'Description', prop: 'description', sortable: false },
    { name: 'Status', prop: 'status' },
    { name: 'storage_location', prop: 'storage_location'}
  ];

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  constructor(
    private rest: RestService,
    public modalController: ModalController,
    public storage: StorageService
  ) { }

  ngOnInit() {
    SplashScreen.show();
    this.rest.get('inventory/assets/')
      .subscribe(
        (res) => {
          this.inventory = res;
          this.rows = res;
          SplashScreen.hide();
          console.log(this.inventory);
        },
        (err) => {
          console.log(err);
        }
      )
  }

  async onSelect($event: any) {
    console.log('Select Event', $event, this.selected);
    const modal = await this.modalController.create({
      component: AssetDetailComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        'assetId': this.selected[0].tag
      },
      swipeToClose: true
    });
    return await modal.present();
  }

  async rapidLaunchAsset(assetId: string) {
    const modal = await this.modalController.create({
      component: AssetDetailComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        'assetId': assetId
      },
      swipeToClose: true
    });
    return await modal.present();
  }

  async newAsset() {
    const modal = await this.modalController.create({
      component: AssetNewComponent,
      cssClass: 'my-custom-class',
      componentProps: {},
      swipeToClose: true
    });

    await modal.present();

    return await modal.onDidDismiss().then(
      (res) => {
        this.ngOnInit();
      }
    );


  }

  async showQrModel() {
    const modal = await this.modalController.create({
      component: QrscanComponent,
      cssClass: 'my-custom-class',
      componentProps: {},
      swipeToClose: true
    });

    await modal.present();

    return await modal.onDidDismiss().then(
      (res) => {

        if(this.storage.newAssetPrintOnComplete){
          this.rest.get('functions/printLabel/'+this.storage.newAssetId+'/')
        }

        if(this.storage.newAssetLaunch){
          this.rapidLaunchAsset(this.storage.newAssetId);
        }

        this.ngOnInit();
      }
    );
  }
}
