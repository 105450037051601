import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-check-in-out',
  templateUrl: './check-in-out.component.html',
  styleUrls: ['./check-in-out.component.scss'],
})
export class CheckInOutComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
