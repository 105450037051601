import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public newAssetId = "";
  public newAssetLaunch: boolean = false;
  public newAssetPrintOnComplete: boolean = false;
  public newAssetStayOnThisPage: boolean = false;

  constructor() {

  }
}
