import { Component, OnInit } from '@angular/core';
import {RestService} from "../../services/rest.service";
import {ModalController} from "@ionic/angular";
import {ASSETTYPES} from "../../dictionaries/asset-types";
import {ASSETSTATUSES} from "../../dictionaries/asset-status";
import {Asset} from "../../interfaces/asset";
import {StorageService} from "../../services/storage.service";

@Component({
  selector: 'app-asset-new',
  templateUrl: './asset-new.component.html',
  styleUrls: ['./asset-new.component.scss'],
})
export class AssetNewComponent implements OnInit {
  fullyLoaded: boolean = false;

  assetTypes = ASSETTYPES;
  assetStatus = ASSETSTATUSES;
  assetStorageAreas: any = [];
  assetBoxes: Asset[] = [];

  assetConstruct: Asset = new class implements Asset {
    box: any;
    check_out_department: string;
    check_out_notes: string;
    checked_out_date: string;
    checked_out_to: string;
    company: any;
    constructAssetTag: string;
    cost: string;
    description: string;
    getComments: any;
    is_convention_owned: boolean;
    owner: any;
    serial_number: string;
    status: string;
    storage_location: any;
    tag?: string;
    type: string;
  };

  constructor(
    private rest: RestService,
    public modalController: ModalController,
    public storage: StorageService
  ) { }

  dismissModal() {
    this.modalController.dismiss();
  }
  ngOnInit() {

    this.rest.get('inventory/areas/').subscribe(
      (res) => {
        this.assetStorageAreas = res;
      },
      (err) => {
        console.log(err);
      }
    )

    this.rest.get('inventory/assets/?type=BX').subscribe(
      (res) => {
        this.assetBoxes = res;
        this.fullyLoaded = true;
      },
      (err) => {
        console.log(err);
      }
    )


  }

  createAsset() {

    console.log(this.assetConstruct);
    //return true;
    this.rest.post('inventory/assets-post/', this.assetConstruct).subscribe(

      (res) => {
        console.log(res);
        this.storage.newAssetId = res.tag;
        if(this.storage.newAssetStayOnThisPage){
          if(this.storage.newAssetPrintOnComplete){
            this.rest.get('functions/printLabel/'+res.tag+'/')
              .subscribe(
                (res)=>{
                  console.log(res);
                },
                (err)=>{
                  console.log(err);
                }
              )
          }
        }else{
          this.modalController.dismiss();
        }

      },
      (error) => {
        console.log(error);
      }
    )
  }
}
