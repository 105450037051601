export const ASSETTYPES = [
  ["AP", "Access Point"],
  ["BX", "Box"],
  ["CA", "Card Printer"],
  ["CH", "Chromebook"],
  ["CP", "Computer"],
  ["LO", "Loaned Hardware"],
  ["MN", "Monitor"],
  ["OT", "Other"],
  ["PR", "Traditional Printer"],
  ["RD", "Radio"],
  ["RT", "Router"],
  ["SV", "Server"],
  ["SW", "Networking Switch"],
  ["TB", "Tablet"]
]
