import {Component, Input, OnInit} from '@angular/core';
import {RestService} from "../../services/rest.service";
import {Asset} from "../../interfaces/asset";
import {ModalController} from "@ionic/angular";
import {ASSETSTATUSESDICT} from "../../dictionaries/asset-status";

@Component({
  selector: 'app-asset-detail',
  templateUrl: './asset-detail.component.html',
  styleUrls: ['./asset-detail.component.scss'],
})
export class AssetDetailComponent implements OnInit {

  @Input() assetId: string = "";

  asset: Asset;
  fullyLoaded: boolean = false;

  public statusDict = ASSETSTATUSESDICT;

  constructor(
    private rest: RestService,
    public modalController: ModalController
  ) { }

  dismissModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.rest.get('inventory/assets/'+this.assetId+'/')
      .subscribe(
        (res) => {
          this.asset = res;
          this.fullyLoaded = true;
          console.log("Loaded.")
        },
        (err) => {
          console.log(err);
        }
      )
  }



  printTag() {
    this.rest.get('functions/printLabel/'+this.assetId+'/')
      .subscribe(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      )
  }
}
