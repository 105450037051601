export const ASSETSTATUSESDICT = {
  danger: 'Checked In - Stored',
  warning: 'Checked Out - On Long Term Loan',
  primary: 'Checked In - Ready for Use',
  secondary: 'In Transit - Transporting',
  success: 'Checked Out - In Use',
  tertiary: 'Decommissioned'
}

export const ASSETSTATUSES = [
  ["danger", "Checked In - Stored"],
  ["warning", "Checked Out - On Long Term Loan"],
  ["primary", "Checked In - Ready for Use"],
  ["secondary", "In Transit - Transporting"],
  ["success", "Checked Out - In Use"],
  ["dark", "Decommissioned"]
]
