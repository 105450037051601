import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HttpClientModule} from "@angular/common/http";
import {CommonModule} from "@angular/common";
import {ListComponent} from "./pages/list/list.component";
import {CheckInOutComponent} from "./pages/check-in-out/check-in-out.component";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {AssetDetailComponent} from "./models/asset-detail/asset-detail.component";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {AssetNewComponent} from "./models/asset-new/asset-new.component";
import {FormsModule} from "@angular/forms";
import {QrscanComponent} from "./models/qrscan/qrscan.component";
import {BarcodeScanner} from "@ionic-native/barcode-scanner/ngx";

@NgModule({
  declarations: [
    AppComponent,
    ListComponent,
    CheckInOutComponent,
    AssetDetailComponent,
    AssetNewComponent,
    QrscanComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios'
    }),
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    NgxDatatableModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    FormsModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, BarcodeScanner],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {}
